export const STATUS_DELIVERED = "Delivered";
export const MESSAGE_DELIVERED_AT = "Delivered at";
export const MESSAGE_DELIVERY_IN = "Delivery in";
export const MESSAGE_DELIVERY_TODAY = "Delivery today";
export const TEXT_DAY = "day";
export const TEXT_DAYS = "days";
export const MESSAGE_ARRIVING_ON = "Arriving on";
export const UNABLE_TO_PROVIDE_UPDATES = "We're unable to provide updates for this package at the moment, please try again later";
export const WE_DO_NOT_HAVE_UPDATES = "We do not have updates for this package at the moment, please try again later";
export const ARRIVING_LATE = "Arriving Late";
export const DELAYED = "Delayed";