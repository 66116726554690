import { differenceInCalendarDays, isPast, isToday, isTomorrow } from "date-fns";
import {
    DELAYED,
    MESSAGE_DELIVERY_IN,
    MESSAGE_DELIVERY_TODAY,
    STATUS_DELIVERED,
    TEXT_DAY, TEXT_DAYS
} from "../shared/strings/translations";
const getFooterMessage = (isDelivered: boolean, eddEpoch: number) => {
    if (isDelivered) {
        return STATUS_DELIVERED;
    }
    else if (eddEpoch === 0) {
        return "---";
    }
    else if (isPast(eddEpoch)) {
        return DELAYED;
    }
    else if (isToday(eddEpoch)) {
        return MESSAGE_DELIVERY_TODAY;
    }
    else if (isTomorrow(eddEpoch)) {
        return `${MESSAGE_DELIVERY_IN} 1 ${TEXT_DAY}`;
    }
    return `${MESSAGE_DELIVERY_IN}  ${differenceInCalendarDays(new Date(Number(eddEpoch)), new Date())}  ${TEXT_DAYS}`;
};

export default getFooterMessage;