import React from "react";
import TrackingWidgetContainer from "./containers/trackingWidgetContainer";
import { QueryClientProvider, QueryClient } from "react-query";

const queryClient = new QueryClient();
function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <TrackingWidgetContainer/>
    </QueryClientProvider>
  );
}

export default App;
