import { intlFormat, isPast } from "date-fns";
import { ShipmentEvent } from "../shared/interfaces";
import { ARRIVING_LATE, MESSAGE_ARRIVING_ON, MESSAGE_DELIVERED_AT } from "../shared/strings/translations";

interface FormatOptions{
    localeMatcher?: "lookup" | "best fit"
    weekday?: "narrow" | "short" | "long"
    era?: "narrow" | "short" | "long"
    year?: "numeric" | "2-digit"
    month?: "numeric" | "2-digit" | "narrow" | "short" | "long"
    day?: "numeric" | "2-digit"
    hour?: "numeric" | "2-digit"
    minute?: "numeric" | "2-digit"
    second?: "numeric" | "2-digit"
    timeZoneName?: "short" | "long"
    formatMatcher?: "basic" | "best fit"
    hour12?: boolean
    timeZone?: string
}

const getDeliveryMessage = (isDelivered: boolean, eddEpoch: number, lastestShipmentEvent: ShipmentEvent) => {
    const dateFormatOptions : FormatOptions = {
        day: "2-digit",
        month: "short",
        year: "numeric"
    };
    if (isDelivered) {
        const formattedDate = intlFormat(Number(lastestShipmentEvent.eventTime), dateFormatOptions);
        return `${MESSAGE_DELIVERED_AT} : ${formattedDate}`;
    }
    if (isPast(eddEpoch)) {
        return ARRIVING_LATE;
    }
    if(eddEpoch === 0) {
        return `${MESSAGE_ARRIVING_ON} : ---`;
    }
    const formattedEdd = intlFormat(Number(eddEpoch), dateFormatOptions);
    return `${MESSAGE_ARRIVING_ON} : ${formattedEdd}`;
};

export default getDeliveryMessage;