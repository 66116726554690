export const apiConfig = {
    local: {
        EU: {
            getTrackingDetails: "https://tw5090y595.execute-api.us-west-2.amazonaws.com/test/rttv-gettrackingdetails"
        },
        US:{
            getTrackingDetails: "https://tw5090y595.execute-api.us-west-2.amazonaws.com/test/rttv-gettrackingdetails"
        }
    },
    beta: {
        EU: {
            getTrackingDetails: "https://widget-auth.eu-west-1.alpha.rttv.transportation.amazon.dev/getTrackingDetails"
        },
        US:{
            getTrackingDetails: "https://widget-auth.us-west-2.alpha.rttv.transportation.amazon.dev/getTrackingDetails"
        }
    },
    gamma: {
        EU: {
            getTrackingDetails: "https://auth.api.eu-west-1.eu-gamma.rttv.transportation.amazon.dev/getTrackingDetails"
        },
        US:{
            getTrackingDetails: "https://r9atwc2kld.execute-api.us-east-1.amazonaws.com/prod/getTrackingDetails"
        }
    },
    prod: {
        EU: {
            getTrackingDetails: "https://widget-auth.api.eu-west-1.eu-prod.rttv.transportation.amazon.dev/getTrackingDetails"
        },
        US:{
            getTrackingDetails: "https://widget-auth.api.us-east-1.na-prod.rttv.transportation.amazon.dev/getTrackingDetails"
        }
    }
};