import { validateRegionValue } from "../utils/validations/validateRegionValue";
import { validateThemeValue } from "../utils/validations/validateThemeValue";
import { ClientInputKeyModel } from "../shared/interfaces";

export const AUTHENTICATION_TOKEN_KEY = "Rttv-Widget-AuthToken";
export const API_KEY = "Rttv-Widget-APIKey";
export const validRegions = ["US", "EU"];
export const validThemes = ["Blue-Light", "Blue-Dark", "Orange-Light", "Orange-Dark", "Teal-Light", "Teal-Dark"];
export const clientInputKeys : Array<ClientInputKeyModel> = [
    { key: "theme", clientKey : "Rttv-Widget-Theme", optional: true, defaultValue: "Blue-Light", validationFunction : validateThemeValue },
    { key: "apikey", clientKey : "Rttv-Widget-APIKey", optional: true },
    { key: "authorization", clientKey : "Rttv-Widget-AuthToken", optional: true },
    { key: "tenantId", clientKey : "Rttv-Widget-TenantId" },
    { key: "dataSourceConfigurationId", clientKey : "Rttv-Widget-DataSourceConfigurationId" },
    { key: "trackingId", clientKey : "Rttv-Widget-TrackingId" },
    { key: "carrierId", clientKey : "Rttv-Widget-CarrierId" },
    { key: "region", clientKey : "Rttv-Widget-Region", validationFunction : validateRegionValue }
];