import { ShipmentEvent, TrackingEvent } from "../../shared/interfaces";
import {
    initialShipmentEvent,
    translationkeyToTextValueMapper
} from "../../shared/constants";
import { formatEventAddress } from "../formatEventAddress";

export const transformTrackingEventToShipmentEvent = ({ eventAddress, eventCode, eventShortDescription, eventTimeEpoch, eventDescription } : TrackingEvent, index : number) => {
    const shipmentEvent: ShipmentEvent = Object.assign({}, initialShipmentEvent);
    shipmentEvent.eventId = index.toString();
    shipmentEvent.eventTime = eventTimeEpoch;
    shipmentEvent.eventStatus = eventShortDescription;
    shipmentEvent.eventDescription = translationkeyToTextValueMapper.get(eventDescription.rttvEventDescriptionTranslatorKey);
    shipmentEvent.eventAddress = eventAddress ? formatEventAddress(eventAddress) : "";
    shipmentEvent.eventShortDescription = `${eventShortDescription.slice(0, 1).toUpperCase()}${eventShortDescription.slice(1).toLowerCase()}`.replaceAll("_", " ");
    return shipmentEvent;
};