import TrackingWidget from "@amzn/rttv_web_app/lib/components/molecules/trackingWidget";
import React from "react";
import { useState } from "react";
import { ApiGetTrackingDetailsModel } from "../shared/interfaces";
import { useQuery } from "react-query";
import { transformApiResponseToShipmentDetails } from "../utils/transformations/transformApiResponseToShipmentDetails";
import { DELIVERED, GET_TRACKING_DETAILS_QUERY_KEY } from "../shared/strings/constants";
import getDeliveryMessage from "../utils/getDeliveryMessage";
import getFooterMessage from "../utils/getFooterMessage";
import { initialShipmentDetails } from "../shared/constants";
import { getShipmentDetailsRequest } from "../utils/actions";
import { RenderState } from "@amzn/rttv_web_app/lib/shared/constants";
import { UNABLE_TO_PROVIDE_UPDATES, WE_DO_NOT_HAVE_UPDATES } from "../shared/strings/translations";
import useClientInputs from "../hooks/useClientInputs";
import { getProgressLabelEnum } from "../utils/getProgressLabelEnum";
import logger from "../services/loggerService";
import useToken from "../hooks/useToken";

const TrackingWidgetContainer = () => {
    const { clientInput, isClientInputValid } = useClientInputs();
    const { AuthToken, APIKey } = useToken();
    const [shipmentDetails, setShipmentDetails] = useState(initialShipmentDetails);
    const [apiError, setApiError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(UNABLE_TO_PROVIDE_UPDATES);

    const shipmentDetailsRequest = async () => {
        const response = await getShipmentDetailsRequest(clientInput, AuthToken, APIKey);
        return response;
    };

    const { isLoading, isFetching, isError, refetch } = useQuery([GET_TRACKING_DETAILS_QUERY_KEY],
        shipmentDetailsRequest,
        {
            enabled: isClientInputValid,
            onSuccess: (data) => {
                if ( data?.errorType ) {
                    logger.error(data);
                    setApiError(true);
                    setErrorMessage(UNABLE_TO_PROVIDE_UPDATES);
                    return;
                }
                const shipmentInfo : ApiGetTrackingDetailsModel = JSON.parse(data);
                if (shipmentInfo.trackingResponseAvailable === false) {
                    logger.error("No updates for tracking id", clientInput.trackingId);
                    setApiError(true);
                    setErrorMessage(WE_DO_NOT_HAVE_UPDATES);
                    return;
                }
                setApiError(false);
                setShipmentDetails(transformApiResponseToShipmentDetails(shipmentInfo, clientInput));
            },
            onError: (err) => {
                logger.error("Error while fetching shipment details", err);
                setErrorMessage(UNABLE_TO_PROVIDE_UPDATES);
            }
        });

   const getRenderState = () => {
       if(isLoading || isFetching) {
           return RenderState.LOADING;
       }
       if (!isClientInputValid || apiError || isError) {
           return RenderState.ERROR;
       }
       return RenderState.DATA;
   };

    return (
      <TrackingWidget
        theme={clientInput.theme}
        renderState={getRenderState()}
        errorMessage={errorMessage}
        onRefreshHandler={refetch}
        trackingId={clientInput.trackingId}
        carrierId={clientInput.carrierId}
        currentStatus={shipmentDetails?.currentStatus}
        progressLabel={getProgressLabelEnum(shipmentDetails?.currentStatus)}
        deliveryDateMessage={getDeliveryMessage(shipmentDetails?.currentStatus === DELIVERED, shipmentDetails?.edd, shipmentDetails?.shipmentEvents[0])}
        footerMessage={getFooterMessage(shipmentDetails?.currentStatus === DELIVERED, shipmentDetails?.edd)}
        shipmentEvents={shipmentDetails?.shipmentEvents}
        />
    );
};

export default TrackingWidgetContainer;