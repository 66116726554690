import { ClientInput } from "../../shared/interfaces";
import { getTrackingDetails } from "../apiLinks";

export const getShipmentDetailsRequest = async (clientInput: ClientInput, authToken: string, apiKey: string) => {
    const { region, trackingId, carrierId, tenantId } = clientInput;
    const getShipmentDetailsResponse = await fetch(getTrackingDetails(region), {
        method: "POST",
        mode: "cors",
        referrerPolicy: "origin",
        headers: {
            "Content-Type": "application/json",
            "Authorization": authToken,
            "x-api-key": apiKey
        },
        redirect: "follow",
        body: JSON.stringify({
            "TrackingId": trackingId,
            "CarrierId": carrierId,
            "TenantId": tenantId
        })
    }).then(response => {
        if(response.status === 500) {
          throw new Error("Internal Server Error");
        } else if(response.status === 401) {
          return { errorType: "Token Expired or Unauthorized" };
        } else if (response.status === 403) {
            return { errorType: "Invalid X-API_Token" };
        }
        return response.json();
      });
    return getShipmentDetailsResponse;
};