import { emptyClientInput } from "../shared/constants";
import { ClientInput } from "../shared/interfaces";
import { validateClientInputs } from "../utils/validations/validateClientInputs";
import { clientInputKeys } from "../config/constants";

export default function useClientInputs() {
    const clientInput : ClientInput = Object.assign({}, emptyClientInput);
    clientInputKeys.forEach(({ key, clientKey, defaultValue }) => {
        clientInput[key] = (document?.getElementById(clientKey) as HTMLInputElement)?.value || defaultValue;
    });
    let isClientInputValid = true;
    if (!validateClientInputs(clientInput)) {
        isClientInputValid = false;
    }
    return { clientInput, isClientInputValid };
}