import {
    ClientInput,
    ShipmentDetails,
    ShipmentEvent,
    ApiGetTrackingDetailsModel
} from "../../shared/interfaces";
import { ORDERED } from "../../shared/strings/constants";
import { transformTrackingEventToShipmentEvent } from "./transformTrackingEventToShipmentEvent";
import { isShipmentEventProgressLabel } from "../isShipmentEventProgressLabel";
import { eventTimeSorter } from "../eventTimeSorter";
import { initialShipmentDetails } from "../../shared/constants";
import { validateTrackingEvent } from "../validations/validateTrackingEvent";

export function transformApiResponseToShipmentDetails(apiResponse : ApiGetTrackingDetailsModel, { trackingId, carrierId }: ClientInput) : ShipmentDetails {
    const shipmentDetails: ShipmentDetails = Object.assign({}, initialShipmentDetails);

    shipmentDetails.trackingId = trackingId;

    shipmentDetails.carrierId = carrierId;

    shipmentDetails.trackingRequestId = apiResponse.trackingRequest.trackingRequestId;

    const { trackingResponse } = apiResponse;
    shipmentDetails.edd = trackingResponse.currentStatus?.estimatedDeliveryDate?.endTimeEpoch || 0;

    const shipmentEvents : Array<ShipmentEvent> = trackingResponse.trackingEventList.filter(validateTrackingEvent).map(transformTrackingEventToShipmentEvent);
    shipmentEvents.sort(eventTimeSorter);
    shipmentDetails.shipmentEvents = shipmentEvents;

    const filteredShipmentEvents = shipmentEvents.filter(isShipmentEventProgressLabel);
    shipmentDetails.currentStatus = filteredShipmentEvents ? filteredShipmentEvents[0].eventStatus : ORDERED;

    return shipmentDetails;
};