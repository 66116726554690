import { TrackingEvent } from "../../shared/interfaces";
import { UNDISPLAYED } from "../../shared/strings/constants";
import { translationkeyToTextValueMapper } from "../../shared/constants";

/**
 * Skip event if short description is missing or UNDISPLAYED or translationkey is missing
 * @param eventShortDescription
 */
export const validateTrackingEvent = ({ eventShortDescription, eventDescription } : TrackingEvent) => {
    const description = translationkeyToTextValueMapper.get(eventDescription.rttvEventDescriptionTranslatorKey);
    if (!eventShortDescription || eventShortDescription === UNDISPLAYED || !eventDescription.rttvEventDescriptionTranslatorKey || description === undefined) {
        return false;
    };
    return true;
};