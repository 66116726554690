import { DELIVERED, IN_TRANSIT, ORDERED, OUT_FOR_DELIVERY } from "../shared/strings/constants";
import { ProgressLabel } from "@amzn/rttv_web_app/lib/shared/constants";

export const getProgressLabelEnum = (currentStatus : string) => {
    switch (currentStatus) {
        case (DELIVERED):
            return ProgressLabel[DELIVERED];
        case (OUT_FOR_DELIVERY):
            return ProgressLabel[OUT_FOR_DELIVERY];
        case (IN_TRANSIT):
            return ProgressLabel[IN_TRANSIT];
        default:
            return ProgressLabel[ORDERED];
    }
};