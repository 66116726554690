import { ClientInput } from "../../shared/interfaces";
import { clientInputKeys } from "../../config/constants";

export const validateClientInputs = (clientInput : ClientInput) => {
    clientInputKeys.forEach(({ key, optional, validationFunction }) => {
        if (!optional && !clientInput[key]) {
            return false;
        }
        if (validationFunction && !validationFunction(clientInput[key])) {
            return false;
        }
    });
    return true;
};